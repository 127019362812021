import { exhaustiveSwitchCheck } from "@warrenio/utils/unreachable";
import { useAtomValue } from "jotai/react";
import type { ReactNode } from "react";
import { first } from "remeda";
import { NoticeBlock, type NoticeBlockProps } from "../../components/NoticeBlock.tsx";
import { WButton } from "../../components/button/WButton.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { ResourceCreateLinks } from "../api/resourceTypeLinks.ts";
import { AddPaymentMethodButton, TopUpNowButton } from "./ActionButtons.tsx";
import { billingStateAtom } from "./billingLogic.tsx";
import { firstAccountNoticesAtom } from "./firstAccountNotices.tsx";
import { billingAccountLink } from "./links.ts";
import { getPromotionNotices } from "./promotionNotices.tsx";

export function BillingAccountNotice() {
    //#region Hooks
    const data = useSuspenseQueryAtom(billingStateAtom);
    const firstAccountNotices = useAtomValue(firstAccountNoticesAtom);
    //#endregion

    let items: ReactNode[] = [];
    let button: NoticeBlockProps["button"];
    let color: NoticeBlockProps["color"];

    const { callToAction } = data;
    const { action } = callToAction;

    const promotions = getPromotionNotices(data.accounts);

    switch (action) {
        case "create_account": {
            button = (
                <WButton color="primary" action={ResourceCreateLinks.billing_account}>
                    Create First Billing Account
                </WButton>
            );

            items.push(
                <p key="first">
                    To start using the platform you need to first setup billing account. You are welcome to click around
                    to familiarize yourself with the system and come back to billing account creation at any time you
                    are ready to start building your first resources.
                </p>,
            );

            if (firstAccountNotices.length) {
                items.push(
                    <ul key="options" className="m-0 pl-5">
                        {firstAccountNotices.map(({ content, id }) => (
                            <li key={id}>{content}</li>
                        ))}
                    </ul>,
                );
            }

            break;
        }
        case "must_top_up":
        case "add_payment_method": {
            const { account } = callToAction;
            items.push(<p key="no_active">Without an active billing account, resources can not be created.</p>);
            switch (action) {
                case "add_payment_method":
                    button = <AddPaymentMethodButton account={account} />;
                    items.push(
                        <p key="bill">
                            To activate an account, please add a credit card as a payment method or{" "}
                            <b>contact support</b> to enable invoice payments.
                        </p>,
                    );
                    break;
                case "must_top_up":
                    button = <TopUpNowButton account={account} />;
                    items.push(<p key="bill">Your account must have positive balance to get activated.</p>);
                    break;
            }
            break;
        }
        case "contact_support": {
            const { account } = callToAction;
            color = "error";
            button = (
                <WButton color="error" action={billingAccountLink(account)}>
                    View details
                </WButton>
            );
            items = [
                <p key="suspended">
                    Your billing account has been suspended. See billing account details for more information and{" "}
                    <b>contact support</b> to resolve the issue.
                </p>,
            ];
            break;
        }
        case null: {
            // If there was an immediate action to take, we used that, now try to use the promotion action.

            // Arbitrarily pick the first promotion as the one for which we display an action.
            const promotion = first(promotions);
            if (promotion) {
                const promotionAction = promotion.action;
                if (promotionAction === "top_up") {
                    button = <TopUpNowButton account={promotion.account} />;
                } else {
                    exhaustiveSwitchCheck(promotionAction);
                }
            }
            break;
        }
        default:
            exhaustiveSwitchCheck(action);
    }

    for (const { id, content } of promotions) {
        items.push(<p key={id}>{content}</p>);
    }

    if (!items.length) {
        return null;
    }

    return (
        <NoticeBlock icon="jp-warning-icon" button={button} color={color}>
            {items}
        </NoticeBlock>
    );
}
