import { WButton } from "../../components/button/WButton.tsx";
import { NoticeBlock } from "../../components/NoticeBlock.tsx";
import { accessImpersonationListQuery } from "../access/impersonation/apiOperations.ts";
import { profileLink } from "../api/resourceTypeLinks.ts";
import { useStandardSuspenseQuery } from "../api/useStandardMutation.ts";

export function InvitationNotice() {
    const { data } = useStandardSuspenseQuery(accessImpersonationListQuery);

    const pending = [...data.values()].filter((item) => !item.is_accepted);
    if (!pending.length) {
        return null;
    }

    return (
        <NoticeBlock
            icon="jp-warning-icon"
            button={
                <WButton color="primary" action={profileLink}>
                    View More
                </WButton>
            }
        >
            <b className="uppercase">Access has been shared with you</b>
            <br />
            {pending.map((item) => (
                <span className="block" key={item.uuid}>
                    Access invitation from {item.access_owner} is pending
                </span>
            ))}
        </NoticeBlock>
    );
}
