import C from "./Dashboard.module.css";

import { Link as RouterLink, useNavigate } from "@tanstack/react-router";
import { discardPromise } from "@warrenio/utils/promise/discardPromise";
import { SiteName } from "../../components/SiteName.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { cn } from "../../utils/classNames.ts";
import { ApiDocLink } from "../access/token/ApiDocLink.tsx";
import { accessCreateLink } from "../api/resourceTypeLinks.ts";
import { useThemeProps } from "../theme/useTheme.ts";

export function WelcomeBlock() {
    const { desktopBanner } = useThemeProps();
    const navigate = useNavigate();

    function openThemeMenu() {
        if (desktopBanner?.url) {
            discardPromise(navigate({ to: desktopBanner.url }));
        } else {
            const btn: HTMLButtonElement | null = document.querySelector('[aria-label="Appearance"]');
            btn?.click();
        }
    }

    console.log(desktopBanner);

    return (
        <div className={C.WelcomeBlock}>
            <div className={cn(C.Block, "flex", C.Welcome)}>
                <div>
                    <h1 className="font-size-heading">
                        Welcome to <SiteName />
                    </h1>
                    <p className="color-muted">
                        We've assembled some links to get you started. Develop, test code, and deploy your applications
                        in no time, from prototyping to large-scale development.
                    </p>
                </div>

                <div className={C.Links}>
                    <h2 className="font-size-default">API</h2>
                    <div className="pt-2">
                        <ApiDocLink>
                            <MaskIcon className="jp-icon-text-editor size-0.875rem" /> API Documentation
                        </ApiDocLink>
                    </div>
                    <div className="pt-2">
                        <RouterLink {...accessCreateLink} className="react-aria-Link">
                            <MaskIcon className="jp-swap-icon size-0.875rem" /> Manage API Keys
                        </RouterLink>
                    </div>
                </div>
            </div>

            <div
                className={cn(C.Block, "text-center", C.Banner)}
                style={
                    desktopBanner?.image ? { backgroundImage: desktopBanner.image, ...desktopBanner?.style } : undefined
                }
                onClick={() => openThemeMenu()}
            >
                <MaskIcon
                    className={cn(
                        desktopBanner?.icon ? desktopBanner.icon : "jp-brightness-medium-icon",
                        "size-1.75rem color-primary",
                    )}
                />
                <h2 className="font-size-title">
                    {desktopBanner?.title ? desktopBanner.title : "Embrace the Elegance of Darkness"}
                </h2>
                <p className="font-size-small text-primary">
                    {desktopBanner?.subtitle ? desktopBanner.subtitle : "Dark theme now available"}
                </p>
            </div>
        </div>
    );
}
