import { filterFalse } from "@warrenio/utils/collections/filterFalse";
import { useAtomValue } from "jotai/react";
import { useState } from "react";
import { WTabs, type WTabItem } from "../../components/WTabs.tsx";
import { WButton } from "../../components/button/WButton.tsx";
import { useConfig } from "../../config.ts";
import { AppCatalog } from "./AppCatalog.tsx";
import { CoreServices } from "./CoreServices.tsx";
import { ManagedServices } from "./ManagedServices.tsx";
import { hasNoResourcesAtom } from "./recentQuery.ts";

export function StartSomethingNew() {
    //#region Hooks
    const { servicesEnabled, vmAppsEnabled } = useConfig();
    const hasNoResources = useAtomValue(hasNoResourcesAtom);
    const [selectedMore, setMore] = useState<boolean | undefined>(undefined);
    //#endregion

    // Only set "more" mode automatically once the resources have loaded (this means that they might be displayed in
    // compact mode for a moment).
    const more = selectedMore ?? hasNoResources;

    const tabs = filterFalse<WTabItem>([
        { id: "core", title: "Core Services", content: <CoreServices more={more} /> },
        vmAppsEnabled && { id: "app", title: "App Catalog", content: <AppCatalog more={more} /> },
        servicesEnabled && { id: "managed", title: "Managed Services", content: <ManagedServices more={more} /> },
    ]);

    function toggleMore() {
        setMore(!more);
    }

    return (
        <div>
            <h2 className="font-size-title pl-4">Start Something New</h2>

            <WTabs
                wideHeader
                button={
                    <WButton
                        action={() => toggleMore()}
                        color="primary"
                        icon={more ? "jp-icon-caretup" : "jp-icon-caretdown"}
                        iconSide="right"
                        variant="ghost"
                        size="bar"
                    >
                        {more ? "Less Details" : "More Details"}
                    </WButton>
                }
                items={tabs}
            />
        </div>
    );
}
