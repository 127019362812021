import C from "./Dashboard.module.css";

import type { AccessDelegationImpersonation } from "@warrenio/api-spec/spec.oats.gen";
import { Suspense, type ReactElement } from "react";
import { GridList, GridListItem } from "react-aria-components";
import { sortBy } from "remeda";
import { Badge } from "../../components/Badge.tsx";
import { WTabs } from "../../components/WTabs.tsx";
import { WTooltip } from "../../components/WTooltip.tsx";
import { WButton } from "../../components/button/WButton.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { Loading } from "../../components/loading/Loading.tsx";
import { cn } from "../../utils/classNames.ts";
import { AcceptInvitationModal } from "../access/AcceptInvitationModal.tsx";
import { accessDelegationListQuery, type AccessDelegationWithType } from "../access/delegation/apiOperations.ts";
import {
    accessImpersonationListQuery,
    type AccessImpersonationWithType,
} from "../access/impersonation/apiOperations.ts";
import { profileLink, ResourceCreateLinks } from "../api/resourceTypeLinks.ts";
import { useStandardSuspenseQuery } from "../api/useStandardMutation.ts";
import { isMobileDevice } from "../main/mobile/isMobile.ts";
import { ActAsModal } from "../user/ActAsModal.tsx";
import { NameAvatar } from "../user/UserAvatar.tsx";

interface UserAccessProps {
    impersonations: ReactElement[];
    delegations: ReactElement[];
    isLoading?: boolean;
}

function ImpersonationItem({ item }: { item: AccessImpersonationWithType }) {
    return (
        <GridListItem className={C.ListItem} textValue={item.access_owner}>
            <NameAvatar name={item.access_owner} />

            <div className={cn(C.Content, "font-size-small")}>{item.access_owner}</div>

            <div className={isMobileDevice ? "" : C.Hover}>
                {item.is_accepted ? (
                    <ActAsModal
                        item={item}
                        button={
                            <WButton size="xs" color="primary" variant="ghost" action={undefined}>
                                Act As
                            </WButton>
                        }
                    />
                ) : (
                    <AcceptInvitationModal uuid={item.uuid} name={item.access_owner} />
                )}
            </div>
        </GridListItem>
    );
}

function DelegationItem({ item }: { item: AccessDelegationImpersonation }) {
    return (
        <GridListItem className={C.ListItem} textValue={item.grantee_username}>
            <NameAvatar name={item.grantee_username} />

            <div className={cn(C.Content, "font-size-small")}>{item.grantee_username}</div>

            {!item.is_accepted && (
                <div className={isMobileDevice ? "" : C.Hover}>
                    <WTooltip text="User must accept access invitation from their profile view">
                        <Badge color="warning">Pending</Badge>
                    </WTooltip>
                </div>
            )}
        </GridListItem>
    );
}

export function UserAccessCustom({ impersonations, delegations, isLoading }: UserAccessProps) {
    const tabs = [
        { id: "one", title: "I can access", content: <ICanAccess isLoading={isLoading} items={impersonations} /> },
        { id: "two", title: "Can access me", content: <CanAccessMe isLoading={isLoading} items={delegations} /> },
    ];

    return (
        <div className={C.Access}>
            <div className={cn(C.BlockTitle, "pl-2")}>
                <h2>User Access</h2>

                <WButton
                    className={C.Button}
                    color="primary"
                    variant="ghost"
                    icon="jp-icon-add"
                    action={ResourceCreateLinks.access_delegation}
                >
                    Share Access
                </WButton>
            </div>

            <div className={C.Block}>
                <WTabs compact items={tabs} />
            </div>
        </div>
    );
}

interface UserAccessLoaderProps extends Omit<UserAccessProps, "impersonations" | "delegations"> {}

function UserAccessLoader({ ...props }: UserAccessLoaderProps) {
    const { data: impersonations } = useStandardSuspenseQuery(accessImpersonationListQuery);
    const impersonationItems = sortBy([...impersonations.values()], (a) => a.created_at);
    const { data: delegations } = useStandardSuspenseQuery(accessDelegationListQuery);
    const delegationsItems = sortBy(
        [...delegations.values()].flatMap((item: AccessDelegationWithType) => item.impersonations),
        (a) => a.created_at,
    );

    return (
        <UserAccessCustom
            impersonations={impersonationItems.map((item: AccessImpersonationWithType, index: number) => (
                <ImpersonationItem key={index} item={item} />
            ))}
            delegations={delegationsItems.map((item: AccessDelegationImpersonation, index: number) => (
                <DelegationItem key={index} item={item} />
            ))}
            {...props}
        />
    );
}

export function UserAccess(props: UserAccessLoaderProps) {
    const instantLoading = <Loading key="loading" delay={0} icon="none" />;

    return (
        <Suspense
            fallback={
                <UserAccessCustom
                    {...props}
                    isLoading={true}
                    impersonations={[instantLoading]}
                    delegations={[instantLoading]}
                />
            }
        >
            <UserAccessLoader {...props} />
        </Suspense>
    );
}

interface ICanAccessProps {
    items: ReactElement[];
    isLoading?: boolean;
}

function ICanAccess({ items, isLoading }: ICanAccessProps) {
    return items?.length ? (
        isLoading ? (
            <div className={C.Loading}>{items}</div>
        ) : (
            <>
                <p className="color-muted pt-4 font-size-small">Users I can access</p>

                <GridList className={C.Scrollable} aria-label="Users I can access" selectionMode="single">
                    {items}
                </GridList>

                <div className="text-center pt-4">
                    <ManageProfileButton />
                </div>
            </>
        )
    ) : (
        <div className={cn(C.Empty, "color-muted")}>
            <div>
                <MaskIcon className="jp-user-group-icon size-4rem" />
                <p className="pt-2">No one has shared you access yet</p>
            </div>
        </div>
    );
}

function CanAccessMe({ items, isLoading }: ICanAccessProps) {
    return items?.length ? (
        isLoading ? (
            <div className={C.Loading}>{items}</div>
        ) : (
            <>
                <p className="color-muted pt-4 font-size-small">Users who can access my account</p>

                <GridList className={C.Scrollable} aria-label="Users who can access my account" selectionMode="single">
                    {items}
                </GridList>

                <div className="text-center pt-4">
                    <ManageProfileButton />
                </div>
            </>
        )
    ) : (
        <div className={cn(C.Empty, "color-muted")}>
            <div>
                <MaskIcon className="jp-user-group-icon size-4rem" />
                <p className="pt-2 pb-2">No one can access to your account yet</p>
                <WButton
                    icon="jp-icon-add"
                    variant="ghost"
                    color="primary"
                    action={ResourceCreateLinks.access_delegation}
                >
                    Share Access
                </WButton>
            </div>
        </div>
    );
}

const ManageProfileButton = () => (
    <WButton color="primary" variant="ghost" icon="jp-user-group-icon" action={profileLink}>
        Manage
    </WButton>
);
