import { CurrencyPromo } from "../../components/l10n/Currency.tsx";
import type { EBillingAccount } from "./billingLogic.tsx";
import type { NoticeItem } from "./firstAccountNotices.tsx";

export interface PromotionItem extends NoticeItem {
    action: "top_up";
    account: EBillingAccount;
}

export function getPromotionNotices(accounts: readonly EBillingAccount[]): PromotionItem[] {
    // NB: Finds the first account with a specific type of promotion (to not display duplicates).
    const promos: Record<string, Omit<PromotionItem, "id">> = {};

    for (const account of accounts) {
        const { eligiblePromotions } = account;

        // TODO: what happens when these promotions are for post-pay accounts? Wording should be different.
        // There used to be a check for `isPrePay`, but that seemed excessive.
        const firstFree = eligiblePromotions.find((item) => item.credit_type === "first_free");
        const signupCampaign = eligiblePromotions.find((item) => item.credit_type === "signup_campaign");
        const referralReceiver = eligiblePromotions.find((item) => item.credit_type === "referral_receiver");

        const firstSum = (firstFree?.amount ?? 0) + (signupCampaign?.amount ?? 0);
        if (firstSum) {
            promos.first ??= {
                account,
                action: "top_up",
                content: (
                    <>
                        The first top up will unlock your complimentary free credit of{" "}
                        <b>
                            <CurrencyPromo value={firstSum} />
                        </b>
                        .
                    </>
                ),
            };
        }

        if (referralReceiver) {
            const minimumTopUp = (referralReceiver.top_up_requirement ?? 0) - (referralReceiver.top_up_done ?? 0);

            promos.referral ??= {
                account,
                action: "top_up",
                content: (
                    <>
                        Top up at least{" "}
                        <b>
                            <CurrencyPromo value={minimumTopUp} />
                        </b>{" "}
                        to obtain{" "}
                        <b>
                            <CurrencyPromo value={referralReceiver.amount} />
                        </b>{" "}
                        of referral code bonus.
                    </>
                ),
            };
        }
    }

    return Object.entries(promos).map(([id, item]) => ({ id, ...item }));
}
