import type { ComponentType, ReactElement, ReactNode } from "react";
import invariant from "tiny-invariant";

export type OptionalElement = ReactElement | boolean | null | undefined;

interface SeparatedByProps {
    separator: ComponentType | string;
    wrapper?: ComponentType<{ children: ReactNode }>;
    children?: OptionalElement[];
}

/** Adds the `separator` component type between each child. */
export function SeparatedBy({ separator: Separator, wrapper: Wrapper, children }: SeparatedByProps) {
    if (!children) return null;
    invariant(Array.isArray(children));

    const result: ReactNode[] = [];
    let first = true;
    children.forEach((child, i) => {
        if (!child) return;
        if (!first) result.push(typeof Separator === "string" ? Separator : <Separator key={i * 2} />);
        first = false;
        result.push(Wrapper ? <Wrapper key={i * 2 + 1}>{child}</Wrapper> : child);
    });

    // Wrap in fragment to avoid "children must have keys" warning
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{result}</>;
}
