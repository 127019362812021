import C from "./BillingAccounts.module.css";
import D from "./Dashboard.module.css";

import { exhaustiveSwitchCheck } from "@warrenio/utils/unreachable";
import { Suspense, type ReactElement } from "react";
import type { Settings } from "react-slick";
import { Badge } from "../../components/Badge.tsx";
import { NoticeBlock } from "../../components/NoticeBlock.tsx";
import { WButton } from "../../components/button/WButton.tsx";
import { WCarousel } from "../../components/carousel/WCarousel.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { CurrencyBalance } from "../../components/l10n/Currency.tsx";
import { Loading } from "../../components/loading/Loading.tsx";
import { cn } from "../../utils/classNames.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { ResourceCreateLinks } from "../api/resourceTypeLinks.ts";
import { AddPaymentMethodButton, TopUpNowButton } from "../billing/ActionButtons.tsx";
import { billingStateAtom, type EBillingAccount } from "../billing/billingLogic.tsx";
import { billingAccountLink } from "../billing/links.ts";

export function BillingAccountItem({ item }: { item: EBillingAccount }) {
    const {
        account: { is_recurring_payment_enabled },
        ongoingAmount,
        unpaidAmount,
        closedReason,
        nextAction,
    } = item;
    return (
        <div className={cn(C.BillingAccount, !item.isOpen ? C.Suspended : C.Clear)}>
            <div className={C.Header}>
                <div className={cn(C.Notices, item.isSuspended ? "color-error" : "color-primary")}>
                    {item.isSuspended && (
                        <Badge noDot color="white">
                            Suspended
                        </Badge>
                    )}
                    {item.isLimited && (
                        <Badge noDot color="white">
                            Limited
                        </Badge>
                    )}
                    {item.isDefault && (
                        <Badge noDot color="white">
                            Default
                        </Badge>
                    )}
                </div>

                {is_recurring_payment_enabled && (
                    <div className={C.AutoPay}>
                        Automated Payment
                        <MaskIcon className="jp-icon-checkmark size-0.625rem ml-1" />
                    </div>
                )}

                <div className="font-size-small opacity-50">Ongoing Balance</div>
                <div
                    className={cn(
                        "pt-1",
                        // Prevent text from getting too long?
                        // XXX: This is not exactly right since different currencies have different amounts of fractional digits
                        ongoingAmount < 100000 ? "font-size-large" : "font-size-heading",
                    )}
                >
                    <CurrencyBalance value={ongoingAmount} />
                </div>

                {!!unpaidAmount && (
                    <div className="font-size-small pt-1">
                        Unpaid <CurrencyBalance value={-unpaidAmount} />
                    </div>
                )}
            </div>

            <div className={C.Content}>
                <div className="color-muted font-size-small">Account Name</div>
                <div className="font-size-subtitle text-ellipsis">{item.title}</div>

                <div className={C.Holder}>
                    {closedReason && (
                        <NoticeBlock
                            color={closedReason === "suspended" ? "error" : "primary"}
                            compact
                            icon="jp-warning-icon"
                        >
                            {(() => {
                                switch (closedReason) {
                                    case "suspended":
                                        return "Your account has been suspended";
                                    case "missing_payment_method":
                                        return "Verified payment method is missing";
                                    case "insufficient_funds":
                                        return "Insufficient funds";
                                    default:
                                        exhaustiveSwitchCheck(closedReason);
                                }
                            })()}
                        </NoticeBlock>
                    )}

                    {item.isLimited && (
                        <NoticeBlock compact icon="jp-warning-icon">
                            This billing account is limited
                        </NoticeBlock>
                    )}
                </div>

                <div className="text-center pt-4">
                    {(() => {
                        switch (nextAction) {
                            case "add_payment_method":
                                return <AddPaymentMethodButton variant="ghost" account={item} />;
                            case "top_up":
                            case "must_top_up":
                                return <TopUpNowButton variant="ghost" account={item} />;
                            case null:
                            case "contact_support":
                                return (
                                    <WButton
                                        color={nextAction === "contact_support" ? "error" : "primary"}
                                        variant="ghost"
                                        action={billingAccountLink(item)}
                                    >
                                        View Details
                                    </WButton>
                                );
                            default:
                                exhaustiveSwitchCheck(nextAction);
                        }
                    })()}
                </div>
            </div>
        </div>
    );
}

export interface BillingAccountsProps {
    accountItems: ReactElement[];
    accountsIndex?: number;
    createDisabled?: boolean;
}

export function BillingAccountsCustom({
    accountItems,
    accountsIndex = -1,
    createDisabled = true,
}: BillingAccountsProps) {
    const settings: Settings = {
        dots: true,
        dotsClass: cn(D.Dots, "slick-dots"),
        infinite: true,
        adaptiveHeight: true,
        initialSlide: accountsIndex === -1 ? 0 : accountsIndex,
        customPaging: () => <div className={cn(D.Dot, "dot")} />,
    };

    return (
        <div className={D.Accounts}>
            <div className={cn(D.BlockTitle, "pl-2")}>
                <h2>Billing Accounts</h2>

                <WButton
                    className={D.Button}
                    color="primary"
                    variant="ghost"
                    icon="jp-icon-add"
                    action={ResourceCreateLinks.billing_account}
                    isDisabled={createDisabled}
                >
                    Create New
                </WButton>
            </div>

            <div className={D.Block}>
                {accountItems?.length > 1 ? (
                    <WCarousel settings={settings} slideWidth={360}>
                        {accountItems}
                    </WCarousel>
                ) : accountItems.length === 1 ? (
                    accountItems
                ) : (
                    <div className={D.Empty}>
                        <MaskIcon className="jp-wallet-icon size-4rem" />
                        <p>
                            Without an active billing account you can not create any resources, create one to get
                            started.
                        </p>
                        <WButton
                            icon="jp-icon-add"
                            variant="ghost"
                            color="primary"
                            action={ResourceCreateLinks.billing_account}
                            isDisabled={createDisabled}
                        >
                            Create New Billing Account
                        </WButton>
                    </div>
                )}
            </div>
        </div>
    );
}

export interface BillingAccountsPropsLoaderProps
    extends Omit<BillingAccountsProps, "accountItems" | "accountsIndex" | "canCreate"> {}

function BillingAccountsLoader({ ...props }: BillingAccountsPropsLoaderProps) {
    const data = useSuspenseQueryAtom(billingStateAtom);

    return (
        <BillingAccountsCustom
            {...props}
            createDisabled={!!data.createDisabledReason}
            accountsIndex={data.defaultIndex}
            accountItems={data.accounts.map((item) => (
                <BillingAccountItem key={item.id} item={item} />
            ))}
        />
    );
}

export function BillingAccounts(props: BillingAccountsPropsLoaderProps) {
    return (
        <Suspense
            fallback={
                <BillingAccountsCustom
                    {...props}
                    accountsIndex={0}
                    accountItems={[<Loading key="loading" delay={0} icon="none" />]}
                />
            }
        >
            <BillingAccountsLoader {...props} />
        </Suspense>
    );
}
